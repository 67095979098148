// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useCartItems, useGlobalDictionaryQuery } from '../../hooks/index';
import {
  DesktopView,
  MobileView,
  colors,
  desktopBreakpoint,
  desktopVW,
  mobileVW,
  zIndex,
} from '../../styles/index';
import formatPrice from '../../utils/formatPrice';
import ProductFormSkylt from './ProductFormSkylt';

import { useAddItemToCart, useAddToCartTracking, useProductViewTracking } from '../../hooks';

interface Props {
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  slug: any;
  skyltChecked: any;
  setSkyltChecked: any;
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
    id: string;
  };
  setSelectedVariantState: any;
  productTitle: string;
  updateVariants: any;
  quantity: any;
  setQuantity: any;
  category: string;
  allowAddProtection: boolean;
  protectionProduct: any;
  oversell: boolean;
  delivery: 'slow' | 'quick';
  defaultVariantColorSelected?: string;
  thumbnailVariantsImages?: any;
}

export default function ProductFormWithVariantColor(props: Props) {
  const {
    shopifyData,
    skyltChecked,
    setSkyltChecked,
    selectedVariant,
    setSelectedVariantState,
    productTitle,
    updateVariants,
    quantity,
    setQuantity,
    category,
    allowAddProtection,
    protectionProduct,
    slug,
    oversell,
    defaultVariantColorSelected,
    delivery,
    thumbnailVariantsImages,
  } = props;
  const cart = useCartItems();
  const {
    isCartOpen,
    setIsCartOpen,
    purchaseAllowed,
    allProductShopify,
    setMaxQuantityCurrentVariant,
    setQuantityCurrentVariant,
  }: {
    isCartOpen: any;
    setIsCartOpen: any;
    purchaseAllowed: any;
    allProductShopify: any;
    setMaxQuantityCurrentVariant: any;
    setQuantityCurrentVariant: any;
  } = useContext(SettingsContext);
  const dictionary = useGlobalDictionaryQuery();

  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false);
  const [desktopSelectState, setDesktopSelectState] = useState();
  const [lengthOption, setLengthOption] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [variantColorSelected, setVariantColorSelected] = useState('');
  const [variantSizeSelected, setVariantSizeSelected] = useState('');
  const [selectVariantOption, setSelectVariantOption] = useState({
    size: '',
    color: '',
  });
  const [hoverOptionColor, setHoverOptionColor] = useState('');
  const [maxQuantityVariant, setMaxQuantityVariant] = useState(0);
  const [disableButtonQuantityPlus, setDisableButtonQuantityPlus] = useState(false);

  const selectSpring = useSpring({
    transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-102%)',
  });

  const handleSelectVariant = e => {
    setSelectedVariantState(e);
  };

  const handleSizeclickDesktop = sizeTitle => {
    setSelectedVariantState(...shopifyData.variants.filter(({ title }) => title === sizeTitle));
  };

  const addItemToCart = useAddItemToCart();

  const handleAddToCart = async () => {
    // If skylt is selected, pass {key: "skylt", value: "true"} as customAttributes
    // customAttribtues format [{key: "foo", value: "bar"}, {key: "foo2", value: "bar2"}]
    if (allowAddProtection) {
      await addItemToCart(selectedVariant.id, quantity, [
        {
          key: `Extra protection - ${protectionProduct.title}`,
          value: skyltChecked ? 'added' : 'not added',
        },
        // {
        //   key: `statusDelivery`,
        //   value: delivery,
        // },
        // {
        //   key: `quantityAvailable`,
        //   value: maxQuantityVariant?.toString(),
        // },
      ]);
    } else {
      await addItemToCart(selectedVariant.id, quantity);
    }
    useAddToCartTracking(
      selectedVariant.id,
      `${productTitle} - ${selectedVariant.title}`,
      parseFloat(selectedVariant.price),
      location,
      quantity,
      category
    );
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };

  useEffect(() => {
    setQuantity(1);
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedVariant.outOfStock !== null && selectedVariant.id) {
      useProductViewTracking(
        selectedVariant.id,
        `${productTitle} - ${selectedVariant.title}`,
        parseFloat(selectedVariant.price),
        location,
        category
      );
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (cartItems.length === 0 && cart.length > 0) {
      setCartItems(cart);
    }
  }, [cart]);

  useEffect(() => {
    let checkMaxProductQuantity = '';
    if (allProductShopify?.length > 0) {
      for (let i = 0; i < allProductShopify.length; i++) {
        allProductShopify[i].node.variants.edges.map(item => {
          if (selectedVariant.sku === item.node.sku) {
            checkMaxProductQuantity = item;
          }
        });
      }
    }
    if (checkMaxProductQuantity != '') {
      setMaxQuantityVariant(checkMaxProductQuantity?.node?.quantityAvailable);
      setMaxQuantityCurrentVariant(checkMaxProductQuantity?.node?.quantityAvailable);
    }
  }, [allProductShopify, selectedVariant]);

  useEffect(() => {
    let checkTotalQuantity = [];
    checkTotalQuantity = cartItems.filter(item => item?.variant?.sku === selectedVariant?.sku);
    if (checkTotalQuantity.length > 0) {
      setTotalQuantity(checkTotalQuantity[0].quantity + quantity);
      setQuantityCurrentVariant(checkTotalQuantity[0].quantity + quantity);
    } else {
      setTotalQuantity(quantity);
      setQuantityCurrentVariant(quantity);
    }
  }, [cartItems, selectedVariant, quantity]);

  useEffect(() => {
    if (selectVariantOption.color != '' && selectVariantOption.size != '') {
      let selected = shopifyData.variants.filter(
        (item: any) =>
          item.title.replaceAll(' ', '').includes(selectVariantOption.size) &&
          item.title.includes(selectVariantOption.color)
      )[0];

      if (selected.id != selectedVariant.id) {
        handleSelectVariant(selected);
      }
    }
  }, [selectVariantOption]);

  useEffect(() => {
    if (selectedVariant?.title != undefined) {
      setSelectVariantOption({
        size: selectedVariant.title.replaceAll(' ', '').split('/')[0],
        color: selectedVariant.title.replaceAll(' ', '').split('/')[1],
      });
    }
  }, [selectedVariant]);

  function removeDuplicates(item: any) {
    return Array.from(new Set(item));
  }

  const removeDuplicateImages = (dataArray: any) => {
    if (dataArray?.length > 0) {
      const seenColors = new Set();
      return dataArray.filter(item => {
        const color = item.variantTitle.split(' / ')[1]; // Ambil warna dari variantTitle
        const duplicate = seenColors.has(color);
        seenColors.add(color);
        return !duplicate;
      });
    } else {
      return dataArray;
    }
  };
  const onSelecetSize = () => {
    let variantSize: any = [];
    let variantSizeTitle: any = [];
    shopifyData.variants?.map((item: any) => {
      let size = item.title.replaceAll(' ', '').split('/')[0];
      variantSize.push(size);
      variantSizeTitle.push(item.title.split('/')[0]);
    });
    variantSize = removeDuplicates(variantSize);
    variantSizeTitle = removeDuplicates(variantSizeTitle);

    return (
      <>
        <MobileView>
          <SizeSelect border={true} style={{ height: lengthOption > 28 ? mobileVW(60) : 'auto' }}>
            <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
            <BoxOptionDesktop>
              {variantSize.map((item: any, i: number) => {
                return (
                  <OptionContent key={`variant-size-${i}`}>
                    {item == selectVariantOption.size ? (
                      <OptionChildMobileSelected
                        onClick={() =>
                          setSelectVariantOption({
                            size: item,
                            color: selectVariantOption.color,
                          })
                        }
                        key={item}
                      >
                        {variantSizeTitle[i]}
                      </OptionChildMobileSelected>
                    ) : (
                      <OptionChildMobile
                        onClick={() =>
                          setSelectVariantOption({
                            size: item,
                            color: selectVariantOption.color,
                          })
                        }
                        key={item}
                      >
                        <p> {variantSizeTitle[i]}</p>
                      </OptionChildMobile>
                    )}
                  </OptionContent>
                );
              })}
            </BoxOptionDesktop>
          </SizeSelect>
        </MobileView>
        <DesktopView>
          <SizeSelect
            border={true}
            marginBottom={true}
            onMouseLeave={() => setTimeout(() => setdesktopSelectOpen(false), 150)}
          >
            <label htmlFor="sizes">{dictionary?.pdpSizeText}</label>
            <BoxOptionDesktop>
              {variantSize.map((item: any, i: number) => (
                <OptionContent>
                  {item == selectVariantOption.size ? (
                    <OptionChildDesktopSelected
                      key={item}
                      onClick={() =>
                        setSelectVariantOption({
                          size: item,
                          color: selectVariantOption.color,
                        })
                      }
                    >
                      <p> {variantSizeTitle[i]}</p>
                    </OptionChildDesktopSelected>
                  ) : (
                    <OptionChildDesktop
                      key={item}
                      onClick={() =>
                        setSelectVariantOption({
                          size: item,
                          color: selectVariantOption.color,
                        })
                      }
                    >
                      <p> {variantSizeTitle[i]}</p>
                    </OptionChildDesktop>
                  )}
                </OptionContent>
              ))}
            </BoxOptionDesktop>
          </SizeSelect>
        </DesktopView>
      </>
    );
  };

  const onSelecetColor = () => {
    let allVariantColor = removeDuplicateImages(thumbnailVariantsImages);
    return (
      <>
        <MobileView>
          <SizeSelect
            border={false}
            marginBottom={false}
            border={false}
            style={{ height: lengthOption > 28 ? mobileVW(60) : 'auto' }}
          >
            <label htmlFor="colors">Colour</label>
            <BoxOptionColorDesktop>
              {allVariantColor?.map((item: any, i: number) => {
                let color = item.variantTitle.replaceAll(' ', '').split('/')[1];
                return (
                  <>
                    {color == selectVariantOption.color ? (
                      <OptionColorChildDesktopSelected
                        key={`variant-color-${i}`}
                        onClick={() =>
                          setSelectVariantOption({
                            size: selectVariantOption.size,
                            color: color,
                          })
                        }
                      >
                        <ItemColorSelected src={item?.thumbnailVariantColor?.fluid?.src} />
                      </OptionColorChildDesktopSelected>
                    ) : (
                      <OptionColorChildDesktop
                        key={`variant-color-${i}`}
                        onClick={() =>
                          setSelectVariantOption({
                            size: selectVariantOption.size,
                            color: color,
                          })
                        }
                      >
                        <ItemColorSelected src={item?.thumbnailVariantColor?.fluid?.src} />
                      </OptionColorChildDesktop>
                    )}
                  </>
                );
              })}
            </BoxOptionColorDesktop>
          </SizeSelect>
        </MobileView>
        <DesktopView>
          <SizeSelect
            border={false}
            marginBottom={false}
            onMouseLeave={() => setTimeout(() => setdesktopSelectOpen(false), 150)}
          >
            <label htmlFor="sizes">Colour</label>
            <BoxOptionColorDesktop>
              {allVariantColor?.map((item: any, i: number) => {
                let color = item.variantTitle.replaceAll(' ', '').split('/')[1];
                return color === selectVariantOption.color ? (
                  <OptionColorChildDesktopSelected
                    key={`variant-color-${i}`}
                    onClick={() =>
                      setSelectVariantOption({
                        size: selectVariantOption.size,
                        color: color,
                      })
                    }
                  >
                    <ItemColorSelected
                      src={item?.thumbnailVariantColor?.fluid?.src}
                      onMouseEnter={() => setHoverOptionColor(color)}
                      onMouseLeave={() => setHoverOptionColor('')}
                    />
                    {hoverOptionColor === color && (
                      <ItemColorSelectedTitle> {color}</ItemColorSelectedTitle>
                    )}
                  </OptionColorChildDesktopSelected>
                ) : (
                  <OptionColorChildDesktop
                    key={`variant-color-${i}`}
                    onClick={() =>
                      setSelectVariantOption({
                        size: selectVariantOption.size,
                        color: color,
                      })
                    }
                  >
                    <ItemColorSelected
                      src={item?.thumbnailVariantColor?.fluid?.src}
                      onMouseEnter={() => setHoverOptionColor(color)}
                      onMouseLeave={() => setHoverOptionColor('')}
                    />
                    {hoverOptionColor === color && (
                      <ItemColorSelectedTitle> {color}</ItemColorSelectedTitle>
                    )}
                  </OptionColorChildDesktop>
                );
              })}
            </BoxOptionColorDesktop>
          </SizeSelect>
        </DesktopView>
      </>
    );
  };

  return (
    <Wrapper>
      {onSelecetColor()}
      {onSelecetSize()}
      <AddToCartWrapper allowAddProtection={allowAddProtection}>
        {allowAddProtection && (
          <ProductFormSkylt
            skyltChecked={skyltChecked}
            setSkyltChecked={setSkyltChecked}
            price={Number(protectionProduct?.price?.amount)}
          />
        )}
        <QuantityBlock>{dictionary?.pdpQuantityText}</QuantityBlock>
        <QuantityCounterBlock>
          <Counter onMouseEnter={() => updateVariants()}>
            <Button
              type="button"
              aria-label="decrease quantity"
              onClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
            >
              -
            </Button>
            <Count>{quantity}</Count>
            <Button
              type="button"
              disabled={!!(totalQuantity >= maxQuantityVariant && oversell === false)}
              aria-label="increase quantity"
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </Button>
          </Counter>
        </QuantityCounterBlock>
        <AddToCartBlock>
          {purchaseAllowed &&
            (purchaseAllowed?.allowed ? (
              <button type="button" aria-label="add to cart" onClick={() => handleAddToCart()}>
                {`${formatPrice(
                  selectedVariant.price && skyltChecked
                    ? selectedVariant.price * quantity +
                        Number(protectionProduct.price.amount) * quantity
                    : selectedVariant.price
                    ? selectedVariant.price * quantity
                    : 0,
                  '€'
                )}
                   - ${dictionary?.addToCartText}`}
              </button>
            ) : (
              <DeniedMsg>{dictionary?.noPurchaseMessage}</DeniedMsg>
            ))}
        </AddToCartBlock>
      </AddToCartWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border-bottom: ${({ border }) => (border ? `${mobileVW(1)} solid ${colors.lightGrey}` : 'none')};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? mobileVW(30) : mobileVW(10))};
  position: relative;
  z-index: 2;

  label {
    font-size: ${mobileVW(18)};
    margin-bottom: ${mobileVW(10)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      margin-bottom: ${desktopVW(10)};
    }
  }

  ${desktopBreakpoint} {
    border-bottom: ${({ border }) =>
      border ? `${desktopVW(1)} solid ${colors.lightGrey}` : 'none'};
    padding-bottom: ${desktopVW(10)};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? desktopVW(30) : desktopVW(20))};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: ${props =>
    props.allowAddProtection
      ? `"skyltBlock skyltBlock" "quantity quantityCounter" "addtoCart addtoCart"`
      : `"quantity quantityCounter" "addtoCart addtoCart"`};
  grid-template-columns: 40% 60%;
  grid-template-rows: ${props =>
    props.allowAddProtection
      ? `${mobileVW(73)} ${mobileVW(90)} ${mobileVW(90)}`
      : `${mobileVW(90)} ${mobileVW(90)}`};
  ${desktopBreakpoint} {
    grid-template-rows: ${props =>
      props.allowAddProtection
        ? `${desktopVW(53)} ${desktopVW(74)} ${desktopVW(74)}`
        : `${desktopVW(74)} ${desktopVW(74)}`};
  }
`;

const VariantSelect = styled.select`
  color: ${colors.black};
  width: auto;
  position: absolute;
  top: 0;
  right: ${mobileVW(30)};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border: none;
  //outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent !important;

  option {
    text-align: right;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(160)};
    margin-left: ${desktopVW(10)};
    font-size: ${desktopVW(14)};
    cursor: pointer;
  }
`;

const VariantSelectHeaderDesktop = styled.div`
  color: ${colors.black};
  width: auto;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border: none;
  font-size: ${desktopVW(14)};
  cursor: pointer;
`;

const SelectOptionsDesktop = styled(animated.div)`
  position: absolute;
  top: ${desktopVW(27.5)};
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
`;
const OptionChildMobile = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  font-size: ${mobileVW(14)};
  border-bottom: ${mobileVW(1)} solid ${colors.lightGrey};
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildMobileSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${mobileVW(45)};
  font-size: ${mobileVW(14)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${mobileVW(10)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(3)} solid #262525;
  width: ${mobileVW(175)};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  width: ${desktopVW(188)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border-bottom: ${desktopVW(1)} solid ${colors.lightGrey};
  p {
    pointer-events: none;
  }
`;

const OptionChildDesktopSelected = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  height: ${desktopVW(40)};
  display: flex;
  width: ${desktopVW(188)};
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(5)};
  background-color: #ece5d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  border: ${desktopVW(2)} solid #262525;
  p {
    pointer-events: none;
  }
`;

const OptionColorChildDesktop = styled.div`
  user-select: none;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};
  pointer-events: all;
  position: relative;
  border-radius: ${mobileVW(60)};
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 0 ${desktopVW(5)};
  margin-right: ${mobileVW(15)};
  background-color: #eae4d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  p {
    pointer-events: none;
  }
  ${desktopBreakpoint} {
    width: ${desktopVW(80)};
    height: ${desktopVW(80)};
    border-radius: ${desktopVW(80)};
    margin-right: ${desktopVW(15)};
  }
`;

const ItemColorSelectedTitle = styled.div`
  position: absolute;
  bottom: -${desktopVW(30)};
  z-index: 10;
  color: #6e6b6b;
  font-size: ${desktopVW(13)};
  background-color: #f2efec;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  padding: ${desktopVW(3)} ${desktopVW(10)} ${desktopVW(4)} ${desktopVW(10)};
  border-radius: ${desktopVW(5)};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
  ${desktopBreakpoint} {
    display: flex;
  }
`;

const OptionColorChildDesktopSelected = styled.div`
  user-select: none;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};
  pointer-events: all;
  position: relative;
  color: ${colors.black};
  border-radius: ${mobileVW(60)};
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-bottom: ${mobileVW(1)};
  border: ${desktopVW(1)} solid black;
  margin-right: ${mobileVW(15)};
  background-color: #eae4d9;
  z-index: ${zIndex.surface + 2};
  cursor: pointer;
  p {
    pointer-events: none;
  }

  ${desktopBreakpoint} {
    width: ${desktopVW(80)};
    height: ${desktopVW(80)};
    border-radius: ${desktopVW(80)};
    padding-bottom: 0;
    border: ${desktopVW(2)} solid black;
    margin-right: ${desktopVW(15)};
  }
`;

const ItemColorSelected = styled.img`
  width: ${mobileVW(55)};
  height: ${mobileVW(55)};
  border-radius: ${mobileVW(55)};
  display: flex;
  align-items: center;
  object-fit: cover;

  ${desktopBreakpoint} {
    width: ${desktopVW(75)};
    height: ${desktopVW(75)};
    border-radius: ${desktopVW(75)};
  }
`;

const BoxOptionColorDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const BoxOptionDesktop = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OptionContent = styled.div`
  margin-bottom: ${desktopVW(5)};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

  ${desktopBreakpoint} {
    right: ${desktopVW(-5)};
    padding-top: 0;
    cursor: pointer;
  }
`;

const QuantityBlock = styled.div`
  grid-area: quantity;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
  }
`;

const QuantityCounterBlock = styled.div`
  grid-area: quantityCounter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  font-size: ${mobileVW(18)};
  border-left: ${mobileVW(1)} solid ${colors.lightGrey};
  border-right: ${mobileVW(1)} solid ${colors.lightGrey};
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};

  ${desktopBreakpoint} {
    border-left: ${desktopVW(1)} solid ${colors.lightGrey};
    border-right: ${desktopVW(1)} solid ${colors.lightGrey};
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
  }
`;

const AddToCartBlock = styled.div`
  grid-area: addtoCart;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  font-size: ${mobileVW(18)};

  button {
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    color: ${colors.cappuccino};
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Counter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  width: 40%;
  height: 100%;
  cursor: pointer;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint} {
    width: 10%;
    height: 100%;
  }
`;

const DeniedMsg = styled.div`
  width: 100%;
  height: 100%;
  border-top: ${mobileVW(1)} solid ${colors.lightGrey};
  font-size: ${mobileVW(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${mobileVW(20)};

  ${desktopBreakpoint} {
    border-top: ${desktopVW(1)} solid ${colors.lightGrey};
    font-size: ${desktopVW(14)};
    padding: 0 ${desktopVW(20)};
  }
`;
function includes(defaultVariantColorSelected: string | undefined): any {
  throw new Error('Function not implemented.');
}
